import React from "react"
// import { AdvancedImage, responsive, placeholder } from '@cloudinary/react'
import { AdvancedImage } from '@cloudinary/react'

import { GetImage } from "../../helpers/CloudinaryHelper"

const ImageCloudinary = ({ image, className, isLazy, width, height }) => {
	const cldImage = GetImage(image)
  var params = {}
  if (isLazy) params.loading = 'lazy'
  if (className && className !== "") params.className = className
  if (width && width !== "") params.width = width
  if (height && height !== "") params.height = height

  return (
    <AdvancedImage
      // cldImg={cldImage.format('auto').quality('auto')}
      cldImg={cldImage}
      alt={image.alternativeText}
      // plugins={[responsive(), placeholder()]}
      {...params}
    />
  )
}

export default ImageCloudinary