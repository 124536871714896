import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import ImageCloudinary from "../_App/ImageCloudinary"

const Locations = ({ title, shortText, locations }) => {
  return (
    <div className="blog-area pt-80 pb-50">
      <div className="container">
        <div className="section-title">
          <h2 className="tb-label">{title}</h2>
          <h3 className="tb-sublabel">{shortText}</h3>
        </div>
        <div className="row justify-content-center">
          {locations.map(location => (
            <div className="col-lg-4 col-md-6" key={location.id}>
              <div className="single-blog-post-box bg-f4faff">
                <div className="entry-thumbnail">
                  <Link to={location.btnLink}>
                    <ImageCloudinary
                      image={location.image}
                      width="393"
                      height="196"
                    />
                  </Link>
                </div>
                <div className="entry-post-content">
                  <h4>{location.country}</h4>
                  <h5>{location.description}</h5>
                  <Link to={location.btnLink} className="learn-more-btn">
                    {location.btnText} <Icon.Plus />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Locations