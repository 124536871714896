import React from "react"

const Offers = ({ title, shortText, offers }) => {
  return (
    <div className="features-area pt-80 pb-50">
      <div className="container">
        <div className="section-title">
          <h2 className="tb-label home-section-label">{title}</h2>
          <h3 className="tb-sublabel home-section-sublabel">{shortText}</h3>
        </div>
        <div className="boxes-area">
          <div className="container">
            <div className="row justify-content-center">
              {offers.map(offer => (
                <div className="col-lg-3 col-sm-6" key={offer.id}>
                  <div className="single-box">
                    <div className="icon">
                      <i className={offer.icon}></i>
                    </div>
                    <h3>{offer.title}</h3>
                    <p>{offer.shortText}</p>
                  </div>
                </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offers