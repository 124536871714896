import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/_App/layout"
import Navbar from "../components/_App/Navbar"
import MainBanner from "../components/Home/MainBanner"
import Partners from "../components/Home/Partners"
import Relocate from "../components/Home/Relocate"
import Offers from "../components/Home/Offers"
import Locations from "../components/Home/Locations"
import Positions from "../components/Home/Positions"
import Words from "../components/Home/Words"
import Footer from "../components/_App/Footer"

const IndexPage = ({ data }) => {
  const { seo, template } = data.page

  return (
    <Layout>
      <Navbar seo={seo} />

      {template.contents.map(content => {
        switch (content.strapi_component) {
          case "home.home-main-banner":
            return (
              <>
                <MainBanner
                  key={content.id}
                  title={content.title}
                  description={content.description}
                  image={content.image}
                />
                <Partners partners={content.partners} />
              </>
            )
          case "home.home-relocate":
            return (
              <Relocate
                key={content.id}
                title={content.title}
                description={content.description}
                image={content.image}
                btnText={content.btnText}
                btnLink={content.btnLink}
              />
            )
          case "home.home-features":
            return (
              <Offers
                key={content.id}
                title={content.title}
                shortText={content.shortText}
                offers={content.offers}
              />
            )
          case "home.home-locations":
            return (
              <Locations
                key={content.id}
                title={content.title}
                shortText={content.shortText}
                locations={content.locations}
              />
            )
          case "home.home-positions":
            return (
              <Positions
                key={content.id}
                title={content.title}
                shortText={content.shortText}
                image={content.image}
                positions={content.positions}
              />
            )
          case "home.home-words":
            return (
              <Words
                key={content.id}
                title={content.title}
                shortText={content.shortText}
                employeeWords={content.employeeWords}
              />
            )
          default:
            return ""
        }
      })}

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetHomePageDetails($id: String) {
    page: strapiPage(id: { eq: $id }) {
      title
      seo {
        metaTitle
        metaDescription
        canonicalURL
        metaRobots
        keywords
        ogTitle
        ogDescription
        ogMetaImage {
          url
        }
        ogURL
        ogType
        twitterTitle
        twitterDescription
        twitterImage {
          url
        }
        twitterSite
        twitterCard
        structuredData {
          data {
            structuredData
          }
        }
      }
      template {
        slug
        contents {
          id
          strapi_component
          title
          description {
            data
          }
          image {
            url
            alternativeText
            caption
            hash
            ext
          }
          partners {
            id
            url
            alternativeText
            caption
            hash
            ext
          }
          btnText
          btnLink
          shortText
          offers {
            id
            title
            shortText
            icon
          }
          locations {
            id
            image {
              url
              alternativeText
              caption
              hash
              ext
            }
            country
            description
            btnText
            btnLink
          }
          positions {
            id
            label
            icon
          }
          employeeWords {
            id
            name
            position
            words
            image {
              url
              alternativeText
              caption
              hash
              ext
            }
          }
        }
      }
    }
  }
`

export default IndexPage