import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import ImageCloudinary from "../_App/ImageCloudinary"

const Process = ({ title, description, image, btnText, btnLink }) => {
  return (
    <div>
      <div className="our-process-container ptb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 our-process-details">
              <ImageCloudinary
                image={image}
                width="601"
                height="515"
              />
            </div>
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2 className="tb-label">{title}</h2>
                <ReactMarkdown source={description.data} />
                <a
                  href={btnLink}
                  className="btn btn-dark our-process-btn"
                  target="_blank"
                >
                  {btnText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Process
