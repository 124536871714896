import React from "react"

import ImageCloudinary from "../_App/ImageCloudinary"

const Positions = ({ title, shortText, image, positions }) => {
  return (
    <div className="services-area ptb-80">
      <div className="container">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 services-left-image">
            <ImageCloudinary
              image={image}
              width="601"
              height="507"
            />
          </div>
          <div className="col-lg-6 col-md-12 services-content">
            <div className="section-title">
              <h2 className="tb-label">{title}</h2>
              <h3 className="tb-sublabel">{shortText}</h3>
            </div>
            <div className="row">
              {positions.map(position => (
                <div className="col-lg-6 col-md-6" key={position.id}>
                  <div className="box">
                    <i className={position.icon}></i> {position.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Positions