import React from "react"
import Slider from "react-slick"
import * as Icon from "react-feather"

import ImageCloudinary from "../_App/ImageCloudinary"

const NextArrow = props => {
  const { onClick } = props
  return (
    <button className="next-arrow slick-arrow" onClick={onClick}>
      <Icon.ArrowRight />
    </button>
  )
}
const PrevArrow = props => {
  const { onClick } = props
  return (
    <button className="prev-arrow slick-arrow" onClick={onClick}>
      <Icon.ArrowLeft />
    </button>
  )
}

class Words extends React.Component {
  state = {
    imagesSlider: null,
    thumbnailsSlider: null,
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
    title: "",
    shortText: "",
    employeeWords: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      shortText: props.shortText,
      employeeWords: props.employeeWords,
    }
  }

  componentDidMount() {
    this.setState({
      imagesSlider: this.slider1,
      thumbnailsSlider: this.slider2,
    })
  }

  renderSliderFullContent = () => {
    return this.state.employeeWords.map(({ id, name, position, image, words }) => {
      return (
        <div className="item" key={id}>
          <div className="single-feedback">
            <div className="client-img">
              <ImageCloudinary
                image={image}
                width="125"
                height="125"
              />
            </div>
            <h4>{name}</h4>
            <span>{position}</span>
            <h5>{words}</h5>
          </div>
        </div>
      )
    })
  }
  renderSliderImages = () => {
    return this.state.employeeWords.map(({ id, image }) => {
      return (
        <div className="item" key={id}>
          <div className="img-fill">
            <ImageCloudinary
              image={image}
              width="70"
              height="70"
            />
          </div>
        </div>
      )
    })
  }

  render() {
    const sliderSettings1 = {
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "linear",
      fade: true,
      autoplay: true,
      draggable: true,
      asNavFor: this.state.thumbnailsSlider,
      draggable: false,
      arrows: false,
      nextArrow: false,
      prevArrow: false,
    }
    const sliderSettings2 = {
      speed: 1000,
      slidesToShow: this.state.employeeWords.length >= 4 ? 4 : this.state.employeeWords.length,
      slidesToScroll: 1,
      cssEase: "linear",
      autoplay: true,
      centerMode: true,
      draggable: false,
      focusOnSelect: true,
      asNavFor: this.state.imagesSlider,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current }),
    }

    return (
      <section className="feedback-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2 className="tb-label">{this.state.title}</h2>
            <h3 className="tb-sublabel">{this.state.shortText}</h3>
          </div>
          <div className="feedback-slides">
            <div className="client-feedback">
              <div>
                <Slider
                  ref={slider => (this.slider1 = slider)}
                  {...sliderSettings1}
                >
                  {this.renderSliderFullContent()}
                </Slider>
              </div>
            </div>
            <div className="client-thumbnails">
              <div>
                <Slider
                  ref={slider => (this.slider2 = slider)}
                  {...sliderSettings2}
                >
                  {this.renderSliderImages()}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Words