import React from "react"

// import ImageCloudinary from "../_App/ImageCloudinary"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 6,
    },
  },
}

const Partners = ({ partners }) => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="repair-partner-area">
      <div className="container">
        {display ? (
          <OwlCarousel
            className="repair-partner-slides owl-carousel owl-theme"
            {...options}
          >
            {partners.map(partner => (
              <div className="single-repair-partner" key={partner.id}>
                <a href={partner.link} target="_blank" rel="noreferrer">
                  <img
                    src={partner.url}
                    alt={partner.alternativeText}
										width="180"
										height="120"
                  />
                  <img
                    src={partner.url}
                    alt={partner.alternativeText}
										width="180"
										height="120"
                  />
                  {/* <ImageCloudinary
										image={partner}
										width="180"
										height="120"
									/>
                  <ImageCloudinary
										image={partner}
										width="180"
										height="120"
									/> */}
                </a>
              </div>
            ))}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default Partners