import { Cloudinary } from "@cloudinary/url-gen"

const cld = new Cloudinary({
	cloud: {
		cloudName: 'duwhhbz6m'
	}
})

export function GetImage(image) {
	return cld.image(`${image.hash}.webp`)
}
export function GetImageUrl(image) {
	return GetImage(image).format('auto').quality('auto').toURL()
}
