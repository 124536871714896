import React from "react"
import ReactMarkdown from "react-markdown"

import ImageCloudinary from "../_App/ImageCloudinary"

const MainBanner = ({ title, description, image }) => {
  return (
    <div className="main-banner">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="hero-content">
                  <h1>{title}</h1>
                  <ReactMarkdown source={description.data} />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div className="banner-image">
                  <ImageCloudinary
										image={image}
										className="animate__animated animate__fadeInUp animate__delay-0.1"
										width="601"
										height="529"
									/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBanner